import Style from "assets/styles/Style"
import React, { useState } from "react"
import DataMap from "../data/DataMap"
import './GridGallery.scss'
import TheatreView from "./TheatreView"

enum Structure {
    gallery = 'grid_gallery',
    item = 'gallery_item'
}
export default function GridGallery(props: PrivateProjects.Assets.Components.Gallery.Import){
    const [selectedImageIndex, setSelectedImage] = useState<number|undefined>(undefined)
    
    return <><div 
        className={`${Structure.gallery}`}
        style={props.style}
        children={
            <DataMap data={props.images} render={({ data: image, index }) => {
                return <div 
                    key={index}
                    title={image.alt}
                    className={`${Structure.item} ${Style.effects.cursor.pointer} ${Style.effects.hoverShadow} ${Style.effects.hoverScale}`}
                    style={{ backgroundImage: `url('${image.url}')` }}
                    onClick={() => {
                        setSelectedImage(index)
                    }} />
            }} />
        } />
        {selectedImageIndex !== undefined ?
            <TheatreView images={props.images} initialIndex={selectedImageIndex} onClose={() => setSelectedImage(undefined)} />
            : null
        }
        </>
}