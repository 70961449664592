import { faFacebookF, faFlickr, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Icon from "assets/components/icon/Icon";
import IconList from "assets/components/icon/IconList";
import React from "react";
import './HeroSection.scss';

enum Structure {
    heroContainer = 'hero_container',
    overlay = 'hero_container_overlay',
    heroMain = 'hero_main',
    heroPreTitle = 'hero_pre_title',
    heroTitle = 'hero_title',
    heroSubtitle = 'hero_subtitle',
    horizontalLine = 'horizontal_line',
    heroSocialIcons = 'hero_social_icons'
}
export default function HeroSection(){
    return <div className={Structure.heroContainer} style={{ backgroundImage: 'url("/assets/images/spinut/applicationFront/hero_main.JPG")' }}>
            <div className={Structure.heroMain}>
                <span className={Structure.heroPreTitle}>Odred izviđača pomoraca</span>
                <h1 className={Structure.heroTitle}>Spinut</h1>
                <div className={Structure.horizontalLine}></div>
                <span className={Structure.heroSubtitle}>Jednom izviđač, uvijek izviđač</span>
            </div>
            <div className={Structure.heroSocialIcons}>
                <a href={'https://facebook.com/oip.spinut'} target='_blank'><Icon icon={IconList.facebook} /></a>
                <a href={'https://instagram.com/oip.spinut.split'} target='_blank'><Icon icon={IconList.instagram} /></a>
                <a href={'https://flickr.com/photos/187474957@N04'} target='_blank'><Icon icon={IconList.flickr} /></a>
                <a href={'https://www.tiktok.com/@oipspinut'} target='_blank'><Icon icon={IconList.tiktok} /></a>
            </div>
        <div className={Structure.overlay}></div>
    </div>
}