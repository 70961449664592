import OrganizationSchema from "assets/publicApplication/components/seo/schema/OrganizationSchema";
import SvgWave2 from "assets/publicApplication/components/svgWave/SwgWave2";
import { getFullUrlFromRelativeUrl } from "assets/publicApplication/utils/Url";
import Style from "assets/styles/Style";
import './ApplicationFront.scss';
import AboutUsSection from "./components/AboutUsSection";
import ApprovedByMinistrySection from "./components/ApprovedByMinistrySection";
import ContactUsSection from "./components/ContactUsSection";
import GallerySection from "./components/GallerySection";
import HeroSection from "./components/HeroSection";

enum Structure {
    applicationFront = 'application_front'
}   
export const spinutHomeUrl = '/'
export default function ApplicationFront(){
    return <>
        <div className={Structure.applicationFront}>
            <HeroSection />
            {/* <LatestNewsSection /> */}
            <AboutUsSection />
            <GallerySection />
            <ApprovedByMinistrySection />
            <ContactUsSection />
            <SvgWave2 color={'#1b1f2a'} style={{ marginBottom: '-2rem', background: Style.variables.colorPrimary }} />
        </div>
    </>
}