import DataMap from "assets/components/data/DataMap";
import Icon from "assets/components/icon/Icon";
import IconList from "assets/components/icon/IconList";
import { disableBody, enableBody } from "assets/helpers/DomHelpers";
import RenderProps from "assets/helpers/RenderProps";
import Style from "assets/styles/Style";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import './PublicMenu.scss'

enum Structure {
    pageMenu = 'page_menu',
    menuLogo = 'menu_logo',
    menuLinks = 'menu_links',
    menuItem = 'menu_item',
    menuToggle = 'menu_toggle',
    menuOpened = 'menu_opened',
    smallMenuStrip = 'small_menu_strip'
}
export default function PublicMenu(props: PrivateProjects.Assets.Components.PublicMenu.Import){
    const [menuVisible, setMenuVisible] = useState(false)

    const toggleMenu = () => {
        if(menuVisible){
            enableBody()
        }
        else{
            disableBody()
        }
        setMenuVisible(!menuVisible)
    }
    const closeMenu = () => {
        enableBody()
        setMenuVisible(false)
    }
    const scrollTo = (pageAnchor: string) => {
        document.getElementById(pageAnchor)?.scrollIntoView({ behavior: 'smooth' })
        closeMenu()
    }

    return <div className={`${Structure.pageMenu} ${menuVisible ? `${Structure.menuOpened} ${Style.animations.slideInFromTop}` : ''}`}>
        <div className={Structure.smallMenuStrip}>
            <div className={Structure.menuLogo}><Link to={'/'}>{RenderProps(props, 'logo')}</Link></div>
            <div className={Structure.menuToggle} onClick={toggleMenu}>
                {menuVisible ? <Icon icon={IconList.close} /> : <Icon icon={IconList.menuToggle} />}
            </div>
        </div>

        <div className={`${Structure.menuLinks}`}>
            <DataMap data={props.routes} render={({ data, index }) => {
                return <div 
                    className={`${Structure.menuItem} ${Style.effects.cursor.pointer}`} 
                    onClick={!data.pageAnchor ? undefined : () => scrollTo(data.pageAnchor!)}
                    key={index}>
                    {data.url ? 
                        <NavLink to={data.url} onClick={closeMenu}>
                            {data.title}
                        </NavLink>
                        :
                        <>{data.title}</>
                    }
                        
                    </div>
            }} />
        </div>
    </div>
}