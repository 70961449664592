import Feedback from "assets/components/feedback/Feedback";
import PublicMenu from "assets/components/menu/public/PublicMenu";
import RenderProps from "assets/helpers/RenderProps";
import ScrollToTop from "assets/helpers/ScrollToTop";
import ScrollToTopButton from "assets/publicApplication/components/scrollToTop/ScrollToTopButton";
import Seo from "assets/publicApplication/components/seo/Seo";
import usePublicApplication from "assets/publicApplication/hooks/publicApplicationSettings/PublicAppSettings";
import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import './PublicApplication.scss';

enum Structure {
    application = 'application',
    content = 'application_content'
}
export default function PublicApplication(props: PrivateProjects.Assets.Components.PublicApplication.Import){
    const { appSettings } = usePublicApplication()

    return <>
        {/* <Seo companyName={ appSettings.companyName } /> */}
        {/* <Helmet>
            {appSettings.companyLogoUrl && <link rel="icon" type="image/x-icon" href={appSettings.companyFaviconUrl} />}
        </Helmet> */}
        <div className={`${Structure.application} ${props.applicationName}`}>
            <BrowserRouter>
                <ScrollToTopButton />
                <ScrollToTop />
                <PublicMenu 
                    logo={props.logo}
                    routes={props.navigationList} 
                />
                <div className={Structure.content}>
                    {RenderProps(props, 'routes')}
                </div>
                {RenderProps(props, 'footer')}
            </BrowserRouter>
            <Feedback />
        </div>
    </>
}