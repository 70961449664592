import Box, { boxStyles } from "assets/components/box/Box";
import Icon from "assets/components/icon/Icon";
import IconList from "assets/components/icon/IconList";
import ResponsiveSection from "assets/components/section/ResponsiveSection";
import getLocales from "assets/locale/Locale";
import StyledHeading from "assets/publicApplication/components/styledHeading/StyledHeading";
import Style from "assets/styles/Style";
import './ContactUsSection.scss';

enum Structure {
    contactBox = 'contact_us_box',
    form = 'contact_us_form_wrapper',
    info = 'contact_us_info_wrapper',
    infoText = 'contact_us_info_text',
    socialMediaContact = 'social_media_contact',
    socialMediaContactIcon = 'social_media_contact_icon',
    messageBackground = 'background_message',
    facebookBackground = 'background_facebook',
    instagramBackground = 'background_instagram',
    flickrBackground = 'background_flickr',
    tiktokBackground = 'background_tiktok',
}
export const contactSectionId = 'contactUs'
export default function ContactUsSection(){
    const { lang } = getLocales()

    return <ResponsiveSection id={contactSectionId} title={lang.hitUsUp} titleBackdrop={lang.contact} render={
    <div className={`${Structure.contactBox}`}>
        <div className={`${Structure.info} ${Style.effects.shadow.dark}`}>
            <img src="/assets/images/spinut/applicationFront/Oip-Spinut-Sator.svg" alt="Šator ilustracija" />
            <div className={Structure.infoText}>
                Izviđačke aktivnosti održavaju se vikendom u prirodi ili u našim izviđačkim prostorijama (Fra Bone Razmilovića 11).
            </div>
        </div>
        <div className={`${Structure.form} ${Style.effects.shadow.light}`}>
            <StyledHeading text={'Pronađite nas ovdje'} style={{ color: Style.variables.colorPrimary, fontSize: '2.3rem' }} />
            <br />
            <Box class={boxStyles.flexBox} style={{ gap: '1rem', flexDirection: 'column' }}>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeqNleBafauCcpop07IY71Pxt--m4_IXNVVtLvP9xwb4Pg9VQ/viewform" target={'_blank'} className={`${Structure.socialMediaContact} hover_${Structure.messageBackground}`}>
                    <div className={`${Structure.socialMediaContactIcon} ${Structure.messageBackground}`}>
                        <Icon icon={IconList.send} />
                    </div>
                    <span>Pošalji nam poruku</span>
                </a>
                <a href="https://facebook.com/oip.spinut" target={'_blank'} className={`${Structure.socialMediaContact} hover_${Structure.facebookBackground}`}>
                    <div className={`${Structure.socialMediaContactIcon} ${Structure.facebookBackground}`}>
                        <Icon icon={IconList.facebook} />
                    </div>
                    <span>Facebook</span>
                </a>
                <a href="https://instagram.com/oip.spinut.split" target={'_blank'} className={`${Structure.socialMediaContact} hover_${Structure.instagramBackground}`}>
                    <div className={`${Structure.socialMediaContactIcon} ${Structure.instagramBackground}`}>
                        <Icon icon={IconList.instagram} />
                    </div>
                    <span>Instagram</span>
                </a>
                <a href="https://flickr.com/photos/187474957@N04" target={'_blank'} className={`${Structure.socialMediaContact} hover_${Structure.flickrBackground}`}>
                    <div className={`${Structure.socialMediaContactIcon} ${Structure.flickrBackground}`}>
                        <Icon icon={IconList.flickr} />
                    </div>
                    <span>Flickr</span>
                </a>
                <a href="https://www.tiktok.com/@oipspinut" target={'_blank'} className={`${Structure.socialMediaContact} hover_${Structure.tiktokBackground}`}>
                    <div className={`${Structure.socialMediaContactIcon} ${Structure.tiktokBackground}`}>
                        <Icon icon={IconList.tiktok} />
                    </div>
                    <span>Tik Tok</span>
                </a>
            </Box>
            
        </div>
    </div>
    } />
}