export default function en(): PrivateProjects.Assets.Locale.Keys{
    return {
        name: 'Name',
        email: 'Email',
        message: 'Message',
        fieldIsRequired: 'Field is required',
        send: 'Send',
        news: 'News',
        homePage: 'Home',
        haveQuestions: 'Have questions?',
        contact: 'Contact',
        hitUsUp: 'Hit us up',
        gallery: 'Gallery',
        latestNews: 'Latest news',
        moreNews: 'More news',
        allRightsReserved:  'All rights reserved',
        notFound: 'Not found',
        noData: 'No data',
        loading: 'Loading',
        aboutUs: 'About us',
        goToTop: 'Go to top',
        repeatTextAbove: 'Repeat text above',
        yourName: 'Your name',
        warningRequiredField: 'Field is required',
        repeatedTexstIsNotValid: 'Repeated text does not match the text above',
        warningNotEmail: 'Invalid email!',
        iAmSending: 'Sending',
        posts: 'Posts',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        welcome: 'Welcome',
        enterUsernameAndPasswordToLogin: 'Enter username and password to login',
        logout: 'Logout',
        users: 'Users',
        total: 'Total',
        title: 'Title',
        actions: 'Actions',
        edit: 'Edit',
        dateCreated: 'Date created',
        author: 'Author',
        new: 'New',
        cancel: 'Cancel',
        submit: 'Submit',
        content: 'Content',
        text: 'Text',
        areYouSure: 'Are you sure?',
        delete: 'Delete',
        yes: 'Yes',
        no: 'No',
        search: 'Search',
        firstName: 'First name',
        lastName: 'Last name',
        ban: 'Ban',
        firstAndLastName: 'Name',
        clickHereToSelectFiles: 'Click here to select files',
        postImage: 'Post image'
    }
}