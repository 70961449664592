import { PublicApplicationProvider } from 'assets/publicApplication/hooks/publicApplicationSettings/PublicAppSettings'
import PublicApplication from 'assets/publicApplication/modules/application/PublicApplication'
import React from 'react'
import './../assets/styles/baseStyle.scss'
import './../assets/styles/Effects.scss'
import Footer from './components/Footer'
import AppRoutes from './routes/AppRoutes'
import getNavigationList from './routes/Navigation'

export default function App(){
    const navigationList = getNavigationList()
    return <PublicApplicationProvider 
        defaults={{
            placeholderImagePath: '/assets/images/spinut/spinut_background_tile.png',
            companyName: 'Odred izviđača pomoraca "Spinut"',
            companyLogoUrl: '/assets/images/spinut/logo.png',
            companyFaviconUrl: '/assets/images/spinut/spinut_favicon_2.svg',
            seoTitle: 'Odred izviđača pomoraca "Spinut"',
            seoDescription: 'Odred izviđača pomoraca "Spinut" je izviđačka udruga koja provodi program koji omogućuje djeci i mladima da postanu savjesne, odgovorne i cjelovite osobe',
            locale: 'hr'
        }}
        render={
            <PublicApplication 
                logo={<img src='/assets/images/spinut/logo.png' alt='Odred izviđača pomoraca "Spinut"' />}
                applicationName='spinut'
                navigationList={navigationList}
                routes={<AppRoutes />}
                footer={<Footer />}
            />
        } />
}