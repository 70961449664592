import RenderProps from "assets/helpers/RenderProps";
import getLocales from "assets/locale/Locale";
import React from "react";
import './PublicFooter.scss';

enum Structure {
    footer = 'public_footer',
    logo = 'logo_box',
    companyInfo = 'company_info',
    copyright = 'copyright_row',
    socialMediaBox = 'social_media_box',
    additionalInfo = 'additional_info'
}
export default function PublicFooter(props: PrivateProjects.Assets.Components.Footer.Import){
    const { lang } = getLocales()

    return <><div className={Structure.footer} style={props.style}><>
        <div className={Structure.logo}>{RenderProps(props, 'logo')}</div>
        <div className={Structure.companyInfo}>{RenderProps(props, 'companyInfo')}</div>
        {props.socialMedia && <div className={Structure.socialMediaBox}>{RenderProps(props, 'socialMedia')}</div>}
    </></div>
    {props.additionalInfo && <div className={Structure.additionalInfo}>{RenderProps(props, 'additionalInfo')}</div>}
    <div className={Structure.copyright}><span>{props.applicationName ? <><span style={{ fontWeight: 700 }}>{props.applicationName}</span>&nbsp;&bull;&nbsp;</> : ''}{lang.allRightsReserved}&nbsp;&bull;&nbsp;{new Date().getFullYear()}</span></div>
    </>
}