export default function hr(): PrivateProjects.Assets.Locale.Keys{
    return {
        name: 'Naziv',
        email: 'Email',
        message: 'Poruka',
        fieldIsRequired: 'Polje je obavezno',
        send: 'Pošalji',
        news: 'Novosti',
        homePage: 'Naslovnica',
        haveQuestions: 'Imate pitanja?',
        hitUsUp: 'Javite se',
        contact: 'Kontakt',
        gallery: 'Galerija',
        latestNews: 'Zadnje novosti',
        moreNews: 'Više novosti',
        allRightsReserved: 'Sva prava pridržana',
        notFound: 'Nije pronađeno',
        noData: 'Nema podataka',
        loading: 'Učitavanje',
        aboutUs: 'O nama',
        goToTop: 'Idi na vrh',
        repeatTextAbove: 'Ponovite gornji tekst',
        yourName: 'Vaše ime',
        warningRequiredField: 'Polje je obavezno',
        repeatedTexstIsNotValid: 'Uneseni tekst se ne slaže s gornjim tekstom',
        warningNotEmail: 'Nevažeća adresa e-pošte!',
        iAmSending: 'Šaljem',
        posts: 'Objave',
        username: 'Korisničko ime',
        password: 'Lozinka',
        login: 'Prijava',
        welcome: 'Dobrodošli',
        enterUsernameAndPasswordToLogin: 'Unesite korisničko ime i lozinku za prijavu',
        logout: 'Odjava',
        users: 'Korisnici',
        total: 'Ukupno',
        title: 'Naslov',
        actions: 'Akcije',
        edit: 'Uredi',
        dateCreated: 'Datum kreiranja',
        author: 'Autor',
        new: 'Novi',
        cancel: 'Odustani',
        submit: 'Spremi',
        content: 'Sadržaj',
        text: 'Tekst',
        areYouSure: 'Jeste li sigurni?',
        delete: 'Izbriši',
        yes: 'Da',
        no: 'Ne',
        search: 'Pretraži',
        firstName: 'Ime',
        lastName: 'Prezime',
        ban: 'Onemogući',
        firstAndLastName: 'Ime i prezime',
        clickHereToSelectFiles: 'Kliknite ovdje za odabir datoteka',
        postImage: 'Slika objave'
    }
}