import { isNullOrEmpty } from "assets/publicApplication/utils/Text";
import Style from "assets/styles/Style";
import React from "react";
import './Feedback.scss'

const FEEDBACK_BOX_ID = 'feedbackBox'
enum Structure {
    feedback = 'feedback',
    success = 'success',
    error = 'error'
}
export default function Feedback(){
    return <div id={FEEDBACK_BOX_ID}></div>
}

export function addLog(log: PrivateProjects.Assets.Data.DefaultResponse){
    const feedbackWindow = document.querySelector(`#${FEEDBACK_BOX_ID}`);

    if(!feedbackWindow){
        return
    }

    const feedbackType = log.error ? 'error' : 'success'

    const newFeedbackBox = document.createElement('div');
    newFeedbackBox.innerHTML = (!isNullOrEmpty(log.success) ? log.success : log.error) || ''
    newFeedbackBox.className = `${Structure.feedback} ${Style.effects.shadow.dark} ${Style.animations.slideInFromBottom} ${feedbackType}`;
    feedbackWindow.append(newFeedbackBox);

    const feedbackLogTimeout: NodeJS.Timeout = setTimeout(() => {
        if (newFeedbackBox) newFeedbackBox.remove();
    }, 6000);
    newFeedbackBox.addEventListener('click', () => {
        if (feedbackLogTimeout) clearTimeout(feedbackLogTimeout);
        if (newFeedbackBox) newFeedbackBox.remove();
    });
}