import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { disableBody, enableBody } from "assets/helpers/DomHelpers";
import useKeyboardEvent, { keyboardKeys } from "assets/helpers/KeyboardEvents";
import useSwipe from "assets/helpers/SwipeEvents";
import Style from "assets/styles/Style";
import { useEffect, useRef, useState } from "react";
import Button, { buttonStyles } from "../button/Button";
import Icon from "../icon/Icon";
import IconList from "../icon/IconList";
import './TheatreView.scss';

enum Structure {
    box = 'theatre_box',
    closeButton = 'close_button',
    nextButton = 'next_button',
    previousButton = 'previous_button',
    quickAccessBox = 'quick_access_buttons',
    quickAccessButton = 'quick_access_button',
    viewPort = 'theatre_view_port'
}
export default function TheatreView(props: PrivateProjects.Assets.Components.TheatreView.Import){
    const [currentIndex, setCurrentIndex] = useState(props.initialIndex || 0)
    const [imageAnimationClass, setImageAnimationStateClass] = useState<string>('')
    const [mainBoxAnimationClass, setMainBoxAnimationClass] = useState<string>(Style.animations.zoomIn)

    const boxRef = useRef() as React.MutableRefObject<HTMLInputElement>

    const close = () => {
        setMainBoxAnimationClass(Style.animations.slideOutDown)
        setTimeout(() => {
            enableBody()
            props.onClose()
        }, Style.settings.animationSpeedInMilliseconds)
    }

    const showNext = () => {
        if(currentIndex !== undefined && props.images && props.images.length > 0){
            setImageAnimationStateClass(Style.animations.slideOutLeft)
            setTimeout(() => {
                setImageAnimationStateClass(Style.uiState.hidden)
                showAtIndex(currentIndex + 1)

                setTimeout(() => {
                    setImageAnimationStateClass(Style.animations.slideInFromRight)
                }, Style.settings.animationSpeedInMilliseconds / 1.1)
            }, Style.settings.animationSpeedInMilliseconds / 1.1)
        }       
    }
    const showPrevious = () => {
        if(currentIndex !== undefined && props.images && props.images.length > 0){
            setImageAnimationStateClass(Style.animations.slideOutRight)
            setTimeout(() => {
                setImageAnimationStateClass(Style.uiState.hidden)
                showAtIndex(currentIndex - 1)

                setTimeout(() => {
                    setImageAnimationStateClass(Style.animations.slideInFromLeft)
                }, Style.settings.animationSpeedInMilliseconds / 1.1)
            }, Style.settings.animationSpeedInMilliseconds / 1.1)
        }       
    }

    const showAtIndex = (newIndex: number) => {
        if(newIndex < 0){
            newIndex = props.images.length - 1
        }
        if(newIndex >= props.images.length){
            newIndex = 0
        }

        setCurrentIndex(newIndex)
    }

    useKeyboardEvent({ keyboardKey: keyboardKeys.escape, handler: close })
    useSwipe({ down: close, left: showNext, right: showPrevious, ref: boxRef })

    useEffect(() => {
        disableBody()
    }, [])

    return <>
    { currentIndex !== undefined && props.images && props.images.length > 0 ?
        <div className={`${Structure.box} ${mainBoxAnimationClass}`} ref={boxRef}>
            <Button class={`${buttonStyles.round} ${Structure.closeButton}`} media={<Icon icon={IconList.close} />} onClick={close} />
            <Button class={`${buttonStyles.round} ${Structure.nextButton}`} media={<Icon icon={IconList.next} />} onClick={showNext} />
            <Button class={`${buttonStyles.round} ${Structure.previousButton}`} media={<Icon icon={IconList.previous} />} onClick={showPrevious} />
            
            <div className={Structure.quickAccessBox}>
                { props.images.map((x, index) => {
                    return index === currentIndex ? 
                        <FontAwesomeIcon key={index} icon={faDotCircle} /> 
                        : 
                        <div className={Structure.quickAccessButton} key={index} onClick={() => showAtIndex(index)}>
                            &bull;
                        </div>
                })

                }
            </div>
            <div className={Structure.viewPort}>
                <img src={props.images[currentIndex].url} alt={props.images[currentIndex].alt} className={imageAnimationClass} />
            </div>
        </div>
        : 
        undefined
    }
        
    </>
}