import RenderProps from "assets/helpers/RenderProps";
import './Box.scss'
import React from "react";

enum Structure {
    box = 'box'
}

export default function Box(props: PrivateProjects.Assets.Components.Box.Import){
    return <div className={`${Structure.box} ${props.class}`} style={props.style}>
        {props.render && RenderProps(props, 'render')}
        {props.children}
    </div>
}
export const boxStyles = {
    flexBox: 'flex',
    flexGrid: (columns: 2|3|4) => {
        return 'flex_grid_' + columns
    }
}