import { useEffect, useRef, useState } from "react"

type Handler = () => void
interface Import {
    up?: Handler
    down?: Handler
    left?: Handler
    right?: Handler
    ref: React.MutableRefObject<HTMLInputElement>
}
interface Point{
    x: number
    y: number
}
type SwipeDirection = 'up'|'down'|'left'|'right'
export default function useSwipe(props: Import){
    const TOUCH_SENSITIVITY = 75

    const [ startPointState, setStartPoint ] = useState<Point|undefined>(undefined)
    
    useEffect(() => {
        const handleTouchStart = (event: TouchEvent) => {
            setStartPoint({ x: event.touches[0].clientX, y: event.touches[0].clientY })
        }

        const handleTouchEnd = (event: TouchEvent) => {
            const endPoint = { x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY }
            const startPoint = startPointState

            if(startPoint){
                const diffX = endPoint.x - startPoint.x
                const diffY = endPoint.y - startPoint.y
                let swipeResult: SwipeDirection|undefined = undefined

                if(Math.abs(diffX) > TOUCH_SENSITIVITY || Math.abs(diffY) > TOUCH_SENSITIVITY){
                    if(Math.abs(diffX) > Math.abs(diffY)){
                        // Horizontal is more significant
                        if(endPoint.x > startPoint.x){
                            swipeResult = 'right'
                        }
                        else{
                            swipeResult = 'left'
                        }
                    }
                    else{
                        // Vertical is more significant
                        if(endPoint.y > startPoint.y){
                            swipeResult = 'down'
                        }
                        else{
                            swipeResult = 'up'
                        }
                    }
                }
                
                if(swipeResult && swipeResult === 'up' && props.up){
                    props.up()
                }
                else if(swipeResult && swipeResult === 'down' && props.down){
                    props.down()
                }
                else if(swipeResult && swipeResult === 'left' && props.left){
                    props.left()
                }
                else if(swipeResult && swipeResult === 'right' && props.right){
                    props.right()
                }

                setStartPoint(undefined)
            }
        }
        
        if(props.ref && props.ref.current){
            props.ref.current.addEventListener('touchstart', handleTouchStart)
            props.ref.current.addEventListener('touchend', handleTouchEnd)
        }

        return () => {
            if(props.ref && props.ref.current){
                props.ref.current.removeEventListener('touchstart', handleTouchStart)
                props.ref.current.removeEventListener('touchend', handleTouchEnd)
            }
        }
    }, [props.ref, startPointState, props.up, props.down, props.left, props.right])
}