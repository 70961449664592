import Box, { boxStyles } from "assets/components/box/Box";
import Button, { buttonStyles } from "assets/components/button/Button";
import PublicFooter from "assets/components/footer/PublicFooter";
import Icon from "assets/components/icon/Icon";
import IconList from "assets/components/icon/IconList";
import Style from "assets/styles/Style";
import './Footer.scss';

enum Structure {
    footerCompanyName = "footer_company_name"
}
export default function Footer(){
    return <>
    <PublicFooter
        logo={<img src="/assets/images/spinut/logo.png" alt="Odred izviđača pomoraca Spinut" />}
        companyInfo={<>
            <Box 
                class={boxStyles.flexBox} 
                style={{ flexDirection: 'column' }}
                render={<>
                    <div className={Structure.footerCompanyName}>Odred izviđača pomoraca "Spinut"</div>
                    <div><span style={{ color: Style.variables.colorPrimaryLight }}>OIB:</span> 98892439111</div>
                    <a href='https://goo.gl/maps/BW76eVYZdUfqreQw8' target={'_blank'}><span style={{ color: Style.variables.colorPrimaryLight }}><Icon icon={IconList.address} /></span>&nbsp; Fra Bone Razmilovića 11</a>
                    <a href='mailto:oipspinut@scouts.hr'><span style={{ color: Style.variables.colorPrimaryLight }}><Icon icon={IconList.email} /></span>&nbsp; oipspinut@scouts.hr</a>
                </>}
            />
        </>}
        additionalInfo={<Box class={`${boxStyles.flexBox} ${'spinut_footer_additional_info'}`} render={<>
            <a href="https://scoutcity.hr/" target={'_blank'}><span style={{ color: Style.variables.colorPrimary }}><Icon icon={IconList.tent} /></span> Splitski skautski zbor</a>
            <a href="https://scouts.hr" target={'_blank'}><span style={{ color: Style.variables.colorSecondary }}><Icon icon={IconList.tent} /></span> Savez izviđača Hrvatske</a>
            <a href="https://www.scout.org" target={'_blank'}><span style={{ color: '#6A2B93' }}><Icon icon={IconList.tent} /></span> WOSM</a>
            <a href="https://www.wagggs.org" target={'_blank'}><span style={{ color: '#4f47f7' }}><Icon icon={IconList.tent} /></span> WAGGGS</a>
        </>} />}
        socialMedia={<>
            <Box class={boxStyles.flexBox} render={<>
                <Button class={buttonStyles.round} style={{ marginRight: '1rem' }} media={<Icon icon={IconList.facebook} />} link={'https://facebook.com/oip.spinut'} />
                <Button class={buttonStyles.round} style={{ marginRight: '1rem' }} media={<Icon icon={IconList.instagram} />} link={'https://instagram.com/oip.spinut.split'} />
                <Button class={buttonStyles.round} style={{ marginRight: '1rem' }} media={<Icon icon={IconList.flickr} />} link={'https://flickr.com/photos/187474957@N04'} />
                <Button class={buttonStyles.round} style={{ marginRight: '1rem' }} media={<Icon icon={IconList.tiktok} />} link={'https://www.tiktok.com/@oipspinut'} />
            </>} />
        </>}
        applicationName="OIP Spinut"
    /></>
}