import Card from "assets/components/card/Card";
import './ApprovedByMinistrySection.scss'
import 'moment/locale/hr';
import Style from "assets/styles/Style";

enum Structure {
    customCardClass = 'approved_by_ministry_card',
}
export default function ApprovedByMinistrySection(){
    
    return (<div style={{ padding: '0 1rem' }}>
            <Card 
                class={`${Structure.customCardClass} ${Style.effects.shadow.primary}`}
                render={<>
                    SCOUTS - Creating a Better World
                </>}
            />
        </div>
)}