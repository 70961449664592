import RenderProps from "assets/helpers/RenderProps";
import React from "react";
import './ResponsiveSection.scss'

enum Structure {
    title = 'section_title',
    content = 'section_content'
}
export default function ResponsiveSection(props: PrivateProjects.Assets.Components.ResponsiveSection.Import){
    return <section style={props.style} className={props.class} id={props.id}>
        {props.title && <h2 className={Structure.title} data-backdrop={props.titleBackdrop}>{props.title}</h2>} 
        <div className={Structure.content} style={props.sectionContentStyle}>{RenderProps(props, 'render')}</div>
    </section>
}