import React from "react";

export default function SvgWave(props: { color: string } & PrivateProjects.Assets.GlobalTypes.StyleableComponent){
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={props.style}>
    <path
      fill={props.color}
      fillOpacity={1}
      d="M0,64L40,64C80,64,160,64,240,85.3C320,107,400,149,480,138.7C560,128,640,64,720,42.7C800,21,880,43,960,58.7C1040,75,1120,85,1200,85.3C1280,85,1360,75,1400,69.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
    />
  </svg>
}