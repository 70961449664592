import Box, { boxStyles } from "assets/components/box/Box";
import ResponsiveSection from "assets/components/section/ResponsiveSection";
import React from "react";
import './AboutUsSection.scss';

enum Structure {
    textBox = 'about_us_text_box',
    image = 'about_us_image'
}
export const aboutUsSectionId = 'aboutUs'
export default function AboutUsSection(){
    return <ResponsiveSection 
        id={aboutUsSectionId}
        title="Tko smo?"
        titleBackdrop="O nama"
        render={
            <Box class={boxStyles.flexGrid(2)} style={{ gridGap: '3rem' }} render={<>
                <div className={Structure.textBox}>
                    Odred izviđača pomoraca "Spinut" osnovan je 22. 12. 1962. i od tada djeluje u gradu Splitu, u kotaru Spinut. Provodimo program koji omogućuje djeci i mladima da kroz druženje i boravak u prirodi postanu savjesne, odgovorne i cjelovite mlade osobe. 
                </div>
                <div>
                    <img src="/assets/images/spinut/applicationFront/Oip-Spinut-Collage.png" alt="Odred izviđača pomoraca Spinut" className={Structure.image} />
                </div>
            </>} />
        } />
}