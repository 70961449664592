import { useEffect } from "react";

interface Import {
    handler: () => void
    keyboardKey: string
}
export default function useKeyboardEvent(props: Import){
    useEffect(() => {
        function onKeyUpEvent(event: KeyboardEvent) {
            if (event.key ===  props.keyboardKey){
                props.handler()
            }
          }
        
          window.addEventListener("keyup", onKeyUpEvent);
          return () => window.removeEventListener("keyup", onKeyUpEvent);
    }, [props])
}

export let keyboardKeys = {
    escape: 'Escape'
}