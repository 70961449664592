import RenderProps from "assets/helpers/RenderProps"
import { setCurrentLocale } from "assets/locale/Locale"
import React, { useContext, useEffect, useState } from "react"

type StoreType = PrivateProjects.PublicApplication.Hooks.PublicApplicationSettings.Model
let PublicApplicationContext:React.Context<{ appSettings?: Partial<StoreType> }>

export default function usePublicApplication(){
    return useContext(PublicApplicationContext as React.Context<{ appSettings: Partial<StoreType> }>)
}
export function PublicApplicationProvider(props: { render: React.ReactNode, defaults?: StoreType }){
    const publicApplicationSettings = usePublicApplicationSettings(props.defaults)
    if(!PublicApplicationContext) PublicApplicationContext = React.createContext(publicApplicationSettings)

    return !publicApplicationSettings.appSettings ? null :
        <PublicApplicationContext.Provider
            value={publicApplicationSettings}
            children={RenderProps(props, 'render', publicApplicationSettings)}
        />
}
export function usePublicApplicationSettings(defaults?: StoreType):{ appSettings?: Partial<StoreType> } {
    const [ appSettings, setAppSettings ] = useState<StoreType>()

    useEffect(() => {
        loadSettings()
    }, [])

    async function loadSettings(){
        setCurrentLocale('hr')
        if(defaults){
            setAppSettings(defaults)
        }
    }
    return {
        appSettings
    }
}