import RenderProps from "../../helpers/RenderProps"

export default function DataMap<T, S>(props:PrivateProjects.Assets.Components.DataMap.Import<T, S>):any{
    
    const _export = (data:T, i:number, key:any, length:number):PrivateProjects.Assets.Components.DataMap.Export<T, S>=>({
        index:i,
        key:key.toString(),
        length:length,
        isFirst:i === 0,
        isLast: i+1 === length,
        data:data,
    })

    if(props.data){
        const dataList:Array<T|string> = Array.isArray(props.data) ? props.data : Object.keys(props.data)
        if(dataList){
            let propsData:any = props.data
            return dataList.map((obj:any, i:number)=>{
                return RenderProps(props, 'render', _export(
                        typeof obj === 'string' && typeof props.data === 'object' && !Array.isArray(props.data) ? propsData[obj] : obj, 
                        i, 
                        typeof obj === 'string' ? obj : i,
                        dataList.length))
            })
        }
        else{
            return null
        }
    }
    else{
        return null
    }
}