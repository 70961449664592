import IconList from "assets/components/icon/IconList";
import getLocales from "assets/locale/Locale";
import { aboutUsSectionId } from "spinut/pages/ApplicationFront/components/AboutUsSection";
import { contactSectionId } from "spinut/pages/ApplicationFront/components/ContactUsSection";
import { gallerySectionId } from "spinut/pages/ApplicationFront/components/GallerySection";

export default function getNavigationList(){
    const { lang } = getLocales()

    return [
        {
            title: lang.aboutUs,
            iconClass: IconList.about,
            pageAnchor: aboutUsSectionId
        },
        {
            title: lang.gallery,
            iconClass: IconList.photo,
            pageAnchor: gallerySectionId
        },
        {
            title: lang.contact,
            iconClass: IconList.contact,
            pageAnchor: contactSectionId
        }
    ] as PrivateProjects.Assets.Components.PublicMenu.Route[]
}