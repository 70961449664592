import PublicPostInfo, { publicPostPageUrl } from "assets/publicApplication/modules/post/layouts/InfoPage"
import PublicPostList, { publicPostListUrl } from "assets/publicApplication/modules/post/layouts/List"
import React from "react"
import { Route, Routes } from "react-router"
import ApplicationFront, { spinutHomeUrl } from "spinut/pages/ApplicationFront/ApplicationFront"

export default function AppRoutes(){
    return <Routes>
        <Route path={spinutHomeUrl} element={<ApplicationFront />}/>
        
        {/* Public app default routes */}
        {/* <Route path={publicPostPageUrl} element={<PublicPostInfo />}/>
        <Route path={publicPostListUrl} element={<PublicPostList />}/> */}
    </Routes>
}