import Box from "assets/components/box/Box";
import Button, { buttonStyles } from "assets/components/button/Button";
import GridGallery from "assets/components/gallery/GridGallery";
import Icon from "assets/components/icon/Icon";
import IconList from "assets/components/icon/IconList";
import { InputWrapper } from "assets/components/inputs/InputShared";
import ResponsiveSection from "assets/components/section/ResponsiveSection";
import getLocales from "assets/locale/Locale";
import SvgWave from "assets/publicApplication/components/svgWave/SvgWave";
import Style from "assets/styles/Style";
import React from "react";
import './AboutUsSection.scss';

const imageList = [
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Boranka.jpg', alt: 'OIP Spinut Boranka' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Logorovanje.jpg', alt: 'OIP Spinut Logorovanje' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Lovinac.jpg', alt: 'OIP Spinut Lovinac' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Radovi.jpg', alt: 'OIP Spinut Radovi' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Scoutball.jpg', alt: 'OIP Spinut Scoutball' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-SV-Nikola.jpg', alt: 'OIP Spinut Sveti Nikola' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Vatra.jpg', alt: 'OIP Spinut Vatra' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Zastava.jpg', alt: 'OIP Spinut Zastava' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Zimovanje.jpg', alt: 'OIP Spinut Zimovanje' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Zimovanje-Dolazak.jpg', alt: 'OIP Spinut Zimovanje Dolazak' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Zimovanje-Vatra.jpg', alt: 'OIP Spinut Zimovanje Vatra' },
    { url: '/assets/images/spinut/gallery/Oip-Spinut-Zimovanje-Zbor.jpg', alt: 'OIP Spinut Zimovanje Zbor' }
]
enum Structure {
    container = 'gallery_container'
}
export const gallerySectionId = 'gallery'
export default function GallerySection(){
    const { lang } = getLocales()

    return <>
        <SvgWave color={Style.variables.colorPrimaryDark} style={{ marginBottom: '-2rem' }} />
        <ResponsiveSection 
            id={gallerySectionId}
            title="Foto Kutak"
            titleBackdrop={lang.gallery}
            style={{ backgroundColor: Style.variables.colorPrimaryDark }}
            render={<>
                <Box class={Structure.container} render={<>
                    <GridGallery images={imageList} />
                </>} />
                <br></br>
                <InputWrapper>
                    <Button
                        class={`${Style.effects.hoverScale}`}
                        media={<Icon icon={IconList.flickr} />}
                        link={'https://flickr.com/photos/187474957@N04'}
                        targetBlank={true}
                        style={{ border: `1px solid ${Style.variables.colorPrimaryLight}`, color: Style.variables.colorTextAlt, marginLeft: 'auto' }}
                        render={'Flickr'} />
                </InputWrapper>
            </>} />
            <div></div>
    </>
}