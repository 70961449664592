import Button, { buttonStyles } from "assets/components/button/Button"
import getLocales from "assets/locale/Locale"
import React, { useEffect, useState } from "react"
import _ from 'lodash'
import Icon from "assets/components/icon/Icon"
import IconList from "assets/components/icon/IconList"
import Style from "assets/styles/Style"

export default function ScrollToTopButton(){
    const [ buttonVisible, setButtonVisible ] = useState<boolean>(false)
    const { lang } = getLocales()

    const handleScroll = (event:Event) => {
        if(!buttonVisible && document.documentElement.scrollTop > 400){
            setButtonVisible(true)
        } 
        else if(buttonVisible && document.documentElement.scrollTop <= 400){
            setButtonVisible(false)
        }
    }
    const throttledScroll = _.throttle(handleScroll, 200)

    useEffect(() => {
        window.addEventListener('scroll', throttledScroll, true)
        return () => { window.removeEventListener('scroll', throttledScroll, true) }
    }, [throttledScroll])

    if(!buttonVisible){
        return null
    }
    else return(
        <Button
            class={`${buttonStyles.round} ${Style.effects.shadow.dark}`}
            style={{ position: 'fixed', right: '1rem', bottom: '1rem', backgroundColor: Style.variables.colorPrimaryLight, color: Style.variables.colorTextAlt, zIndex: 5 }}
            media={<Icon icon={IconList.up} />}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            htmlElementProps={{ title: lang.goToTop }}
        />
    )
}