import RenderProps from "assets/helpers/RenderProps";
import Style from "assets/styles/Style";
import React from "react";
import Icon from "../icon/Icon";
import IconList from "../icon/IconList";
import './Button.scss'

enum Structure {
    button = 'button'
}
export default function Button<T>(props: PrivateProjects.Assets.Components.Button.Import<T>){
    const buttonMain = 
        <button 
            className={`${Structure.button} ${props.class ? props.class : ''} ${!props.notClickable ? Style.effects.cursor.pointer : ''}`}
            style={props.style}
            type={props.type || 'button'}
            data-disabled={props.disabled || props.isLoading || undefined}
            data-primary={props.primary || undefined}
            onClick={() => props.onClick && !props.notClickable && props.onClick(props.data)}
            >
                {props.isLoading && <Icon clazz={Style.animations.spin} icon={IconList.spinner} />}{props.isLoading && props.render && <>&nbsp;&nbsp;</>}
                {!props.isLoading && props.media && RenderProps(props, 'media')}{props.media && props.render && <>&nbsp;&nbsp;</>}
                {props.render && RenderProps(props, 'render')}
        </button>
        if(props.link){
            return <a href={props.link} target={props.targetBlank ? '_blank' : undefined}>
                {buttonMain}
            </a>
        }
        else{
            return buttonMain
        }
}

export const buttonStyles = {
    round: 'btn_round'
}