import RenderProps from "assets/helpers/RenderProps";
import './Card.scss'
import React from "react";

enum Structure {
    card = 'card',
    media = 'card_media',
    mediaOverlay = 'card_media_overlay',
    title = 'card_title',
    subtitle = 'card_subtitle',
    contentBox = 'card_content_box',
    content = 'card_content',
    actions = 'card_actions'
}
export default function Card(props: PrivateProjects.Assets.Components.Card.Import){
    return <div className={`${Structure.card} ${props.class || ''}`} style={props.style}>
        {props.media && <div className={Structure.media}>
            <img src={props.media} />
            <div className={Structure.mediaOverlay}></div>
        </div>}
        <div className={Structure.contentBox}>
            {props.title && <><h2 className={Structure.title}>{props.title}</h2></>}
            {props.subtitle && <><div className={Structure.subtitle}>{props.subtitle}</div></>}
            {(props.title || props.subtitle) && <br />}
            {props.render && <div className={Structure.content}>{RenderProps(props, 'render')}</div>}
            {props.actions && <br />}
            {props.actions && <div className={Structure.actions}>{RenderProps(props, 'actions')}</div>}
        </div>
    </div>
}

export const cardStyles = {
    horizontal: 'card_horizontal'
}