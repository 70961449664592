import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Import {
    icon: IconProp
    clazz?: string
}
export default function Icon({ icon, clazz }: Import){
    return <FontAwesomeIcon style={{ color: 'inherit', fontSize: 'inherit' }} className={clazz} icon={icon} />
}