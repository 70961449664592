import { faFacebookF, faFlickr, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faAt, faBan, faBars, faBullhorn, faCampground, faChevronLeft, faChevronRight, faChevronUp, faClock, faFileInvoice, faFilter, faGlobe, faHome, faImage, faInfoCircle, faKeyboard, faList, faLock, faMapMarkerAlt, faPaperPlane, faPencilAlt, faPhone, faPlus, faSearch, faSignature, faSignOutAlt, faSpinner, faTimes, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";

const IconList = {
    home: faHome,
    about: faInfoCircle,
    contact: faPhone,
    menuToggle: faBars,
    close: faTimes,
    name: faSignature,
    email: faAt,
    text: faKeyboard,
    send: faPaperPlane,
    next: faChevronRight,
    previous: faChevronLeft,
    facebook: faFacebookF,
    instagram: faInstagram,
    flickr: faFlickr,
    address: faMapMarkerAlt,
    phone: faPhone,
    user: faUser,
    clock: faClock,
    tiktok: faTiktok,
    tent: faCampground,
    up: faChevronUp,
    spinner: faSpinner,
    post: faBullhorn,
    password: faLock,
    webApplication: faGlobe,
    logout: faSignOutAlt,
    edit: faPencilAlt,
    add: faPlus,
    filter: faFilter,
    delete: faTrash,
    search: faSearch,
    ban: faBan,
    photo: faImage
}

export default IconList